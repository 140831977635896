import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api/api.service';
import { UserI } from '../../core/shared/interfaces/entities/user.interface';
import { SearchUsersQueryI } from './interfaces/search-users-query.dto';
import { PostI } from '../../core/shared/interfaces/entities/post.interface';
import { ReelI } from '../../core/shared/interfaces/entities/reel.interface';
import { AppointmentI } from '../../core/shared/interfaces/entities/appointments.interface';

@Injectable({
  providedIn: 'root',
})
export class BusinessAccountsApiService {
  constructor(private readonly apiService: ApiService) {}

  search(searchUsersQuery: SearchUsersQueryI) {
    return this.apiService.get<{ users: UserI[]; count: number }>(
      'users/search',
      searchUsersQuery
    );
  }

  findOne(businessAccountID: string) {
    return this.apiService.get<UserI>(`users/${businessAccountID}`);
  }

  findAllPostsForUser(userID: string) {
    return this.apiService.get<PostI[]>(`posts/users/${userID}`, {
      skip: 0,
      limit: 100,
    });
  }
  findAllReelsForUser(userID: string) {
    return this.apiService.get<ReelI[]>(`reels/users/${userID}`, {
      skip: 0,
      limit: 100,
    });
  }
  findAllAppointmentsForUser(userID: string) {
    return this.apiService.get<AppointmentI[]>(`appointments/users/${userID}`, {
      skip: 0,
      limit: 100,
    });
  }
}
